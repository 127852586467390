import gql from 'graphql-tag';
import { bindable } from 'aurelia-templating';
import {
  ReportScheduleReportsFragment,
} from '$typings/graphql';
import { IGridColumn } from '$components/grid/grid';
import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';
import { isNone, orderByPredicate } from '../../../../../utility';
import './report-schedule-reports.css';
import {
  mapReportEnumToTitle,
  mapReportFormatEnumToTitle,
  mapReportPeriodToTitle
} from '../../../schedule-reports-helpers';
import { GraphQLBaseViewModel } from '$pages/common';
import { getLogger } from 'aurelia-logging';
import {
  reportFilterHasValues,
  stringifyReportFilter
} from '$pages/reports/modules/schedule-report-filters/modules/reportFilterUtilities';
import {
  IReportFilter,
  IReportFilterExtended
} from '$pages/reports/modules/schedule-report-filters/modules/IReportFilter';
import { ReportFormatEnum, Reports } from '$typings/graphql-codegen';

type ReportData = ReportScheduleReportsFragment["scheduledReports"][0] 

export const ReportScheduleReportsFragmentDefinition = gql`
  fragment ReportScheduleReportsFragment on ReportSchedule {
    scheduledReports {
      reportFormat
      filterId
      filter {
        filterId
        name
      }
      productFilters {
        values
        partialMatch
        exclude
        translationKeys
      }
      buidFilters {
        values
        partialMatch
        exclude
      }
      controllerTypeFilters {
        values
        partialMatch
        exclude
      }
      accessTagFilters {
        values
        partialMatch
        exclude
      }
      soldToFilters {
        values
        partialMatch
        exclude
      }
      shipToFilters {
        values
        partialMatch
        exclude
      }
      scheduledReportId
      reportPeriod
      report
    }
  }
`;

const rowHeight = 44

@autoinject()
export class ReportScheduleReports extends GraphQLBaseViewModel<
  void,
  void,
  void
> {
  @bindable() schedule: ReportScheduleReportsFragment;
  @bindable() deleteScheduleReport: Function;
  height: number = rowHeight * 2;
  
  constructor(private i18n: I18N) {
    super(getLogger(ReportScheduleReports.name));
  }

  scheduleChanged() {
    this.height = this.schedule.scheduledReports.length * rowHeight + rowHeight;
  }

  navigateToReport = (row: ReportData) => {
    if (!row) return;
    switch (row.report) {
      case Reports.StockVsDose:
        return `/reports/stock-vs-dose/${row.scheduledReportId}`;
      case Reports.ProductStock:
        return `/reports/product-stock/${row.scheduledReportId}`;
      case Reports.NitratesReport:
        return `/reports/nitrates-monthly/${row.scheduledReportId}`;
      case Reports.Alarm:
        return `/reports/alarm/${row.scheduledReportId}`;
      case Reports.UserStatistics:
        return `/reports/user-statistics/${row.scheduledReportId}`;
      case Reports.NewSites:
        return `/reports/new-sites/${row.scheduledReportId}`;
      case Reports.Dosing:
        return `/reports/dosing/${row.scheduledReportId}`;
      case Reports.Temperature:
        return `/reports/temperature/${row.scheduledReportId}`;
      case Reports.IdaDeliveries:
        return `/reports/idadeliveries/${row.scheduledReportId}`;
        case Reports.IdaLifecycle:
          return `/reports/idalifecycle/${row.scheduledReportId}`;
      case Reports.DeviceType:
          return `/reports/device-types/${row.scheduledReportId}`;
      case Reports.DryRun:
        return `/reports/dryruns/${row.scheduledReportId}`;
      case Reports.DailyDose:
        return `/reports/daily-dose/${row.scheduledReportId}`;
    }
    return;
  };

  /** mutates "filters" */
  addSummaryOfFilterIfPresent = (filters: string[], label: string, reportFilters?: IReportFilter[] | null): string[] => {
    const actualReportFilters = reportFilters?.filter(reportFilterHasValues);
    if (!isNone(actualReportFilters) && actualReportFilters.length > 0) {
      filters.push(`${label} ${actualReportFilters.map(stringifyReportFilter).join(', ')}`);
    }
    return filters;
  }

  stringifyReportFilterWithI18n = (filter: IReportFilterExtended) => {
    return filter.translationKeys.map(tk => this.i18n.tr(tk)).join(', ');
  }

  addSummaryOfExtendedFilterIfPresent = (filters: string[], label: string,  reportFilters?: IReportFilterExtended[] | null): string[] => {
    const actualReportFilters = reportFilters?.filter(reportFilterHasValues);
    if (!isNone(actualReportFilters) && actualReportFilters.length > 0) {
      filters.push(`${label} ${actualReportFilters.map(this.stringifyReportFilterWithI18n).join(', ')}`);
    }
    return filters;
  }

  mapFromFiltersToDescription = (row: ReportData): string => {
    const filters: string[] = [];
    
    if (row.filter) filters.push(`Sitegroup: ${row.filter.name}`);
    this.addSummaryOfFilterIfPresent(filters, "BUIDs:", row.buidFilters);
    this.addSummaryOfFilterIfPresent(filters, "Access Tags:", row.accessTagFilters);
    this.addSummaryOfFilterIfPresent(filters, "Controller types:", row.controllerTypeFilters);
    this.addSummaryOfExtendedFilterIfPresent(filters, "Products:", row.productFilters);
    this.addSummaryOfFilterIfPresent(filters, "Sold To:", row.soldToFilters);
    this.addSummaryOfFilterIfPresent(filters, "Ship To:", row.shipToFilters);

    return filters.join('; ');
  };

  gridcolumns: IGridColumn<ReportData>[] = [
    {
      columnTitle: 'Report',
      property: row => mapReportEnumToTitle(this.i18n)(row.report),
      sort: (items, order) =>
        orderByPredicate(
          items,
          row => mapReportEnumToTitle(this.i18n)(row.report),
          order
        ),
      sortedByInitially: 'asc'
    },
    {
      columnTitle: 'Format',
      property: row => mapReportFormatEnumToTitle(row.reportFormat ?? ReportFormatEnum.Excel),
      sort: (items, order) =>
        orderByPredicate(
          items,
          row => mapReportFormatEnumToTitle(row.reportFormat ?? ReportFormatEnum.Excel),
          order
        ),
      sortedByInitially: 'asc'
    },
    {
      columnTitle: 'Period',
      property: row => mapReportPeriodToTitle(row.reportPeriod, row.report),
      sort: (items, order) =>
        orderByPredicate(
          items,
          row => mapReportPeriodToTitle(row.reportPeriod, row.report),
          order
        )
    },
    {
      columnTitle: 'Filter',
      property: this.mapFromFiltersToDescription
    },
    {
      columnTitle: 'Remove',
      type: 'icon',
      property: _ => 'fa-times',
      clickHandler: row =>
        this.deleteScheduleReport({ scheduleReportId: row.scheduledReportId })
    },
    {
      columnTitle: 'Edit',
      property: _ => 'fa-chevron-right',
      type: 'icon',
    }
  ];
}

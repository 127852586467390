import Grid from '$components/grid/react-grid/grid.react';
import { UseGridDataReturnType } from '$components/grid/react-grid/gridhelper';
import Pill from '$components/pill/pill.react';
import { parseHumanDate } from '$lib/dateHelpers';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { useIsMobile } from '$lib/hooks/isMobile';
import { useTimeUntil } from '$lib/hooks/time-until';
import { orderByPredicate } from '$lib/sorting';
import {
  ScheduledReportsListQuery,
  ScheduleTypeEnum,
  SortDirection
} from '$typings/graphql-codegen';
import { Card, CardHeader, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import {
  getLastScheduleRunDescription,
  getScheduleDescription,
  navigateToSchedule,
  useReportScheduleColumns
} from '../report-schedule-list-helper';

interface IReportScheduleSystemListProps {
  data: UseGridDataReturnType<ScheduledReportsListQuery['reportSchedules'][0]>;
}

const ReportScheduleSystemList: FC<IReportScheduleSystemListProps> = ({
  data
}) => {
  const columns = useReportScheduleColumns(true);
  const isMobile = useIsMobile();
  const [t] = useCaseInsensitiveTranslation();
  const [f] = useTimeUntil();

  const [sortValues, setSortValues] = useState<{
    sortColumnKey: 'scheduleName' | 'lastDone' | 'nextTrigger';
    sortDirection: SortDirection;
  }>({
    sortColumnKey: 'scheduleName',
    sortDirection: SortDirection.Asc
  });
  const orderData = (row: ScheduledReportsListQuery['reportSchedules'][0]) => {
    switch (sortValues.sortColumnKey) {
      case 'scheduleName':
        return row.scheduleName;
      case 'lastDone':
        return row.lastTrigger
          ? parseHumanDate(row.lastDone + '').getTime()
          : null;
      case 'nextTrigger':
        return row.nextTrigger
          ? parseHumanDate(row.nextTrigger).getTime()
          : null;
    }
  };

  const orderedData = {
    ...data,
    data: data.data
      ? orderByPredicate(
          data.data.filter(d => d.scheduleType === ScheduleTypeEnum.System),
          orderData,
          sortValues.sortDirection.toLowerCase()
        )
      : undefined
  };

  if (!!orderedData.data?.length && isMobile) {
    return (
      <div>
        <div className="information-text">
          {t('UI_ReportSchedules_NumberOfSchedules', {
            count: orderedData.data?.length
          })}
        </div>
        {orderedData.data.map((scheduler) => (
          <Card
            key={`${scheduler}`}
            className="scheduled-card"
            onClick={() => navigateToSchedule(scheduler)}
          >
            <CardHeader
              classes={{
                root: 'card-header-root',
                content: 'card-header-content'
              }}
              title={
                <Typography variant="h6" noWrap>
                  {scheduler.scheduleName}
                </Typography>
              }
              action={
                <>
                  {scheduler.enabled && (
                    <span className="nextrun">
                      {t('UI_ReportSchedules_NextScheduleRunDescription')}
                      {f(scheduler.nextTrigger)}
                    </span>
                  )}
                  {!scheduler.enabled && (
                    <span className="nextrun disabled">
                      {t('UI_Common_Disabled')}
                    </span>
                  )}
                </>
              }
            />
            <div className="schedule-description pad_m">
              {getScheduleDescription(scheduler, t)}
            </div>
            <div className="schedule-description pad_m">
              {getLastScheduleRunDescription(scheduler, t)}
            </div>
            <div className="schedule-description pad_m">
              <div className="pills">
                {scheduler.systemReportRecipients?.split(",")?.map((recipient) => (
                  <Pill key={`${recipient}`} className="pill">{recipient}</Pill>
                ))}
              </div>
            </div>
          </Card>
        ))}
      </div>
    );
  }

  return (
    <div className="no-padding flex" style={{ height: 400 }}>
      <Grid
        name="report-schedule-system-grid"
        items={orderedData}
        loading={data.isRevalidating && !data.data}
        columns={columns}
        sortedColumnKey={sortValues.sortColumnKey}
        rowLink={navigateToSchedule}
        sortedDirection={sortValues.sortDirection}
        autoWidth
        sortChanged={(sortByColumn, sortDirection) =>
          setSortValues({
            sortColumnKey: sortByColumn,
            sortDirection: sortDirection
          })
        }
      />
    </div>
  );
};

export default ReportScheduleSystemList;

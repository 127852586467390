import { autoinject } from 'aurelia-framework';
import { GraphQLBaseViewModel } from '../../common/GraphQLBaseViewModel';
import gql from 'graphql-tag';
import {
  ReportScheduleQuery,
  ReportScheduleQueryVariables,
  UpsertReportScheduleInput,
  SaveReportScheduleDetailsMutation_upsertReportSchedule,
  SaveReportScheduleDetailsMutationVariables,
  DeleteScheduleReportMutation,
  DeleteScheduleReportMutationVariables,
  DeleteReportScheduleMutation,
  DeleteReportScheduleMutationVariables
} from '../../../../custom_typings/graphql';
import { ReportScheduleDetailsFragmentDefinition } from './modules/report-schedule-details/report-schedule-details';
import { ReportScheduleReportsFragmentDefinition } from './modules/report-schedule-reports/report-schedule-reports';
import { ReportService } from '../../../services/reportService';
import { getLogger } from 'aurelia-logging';
import './report-schedule.css';
import { Router } from 'aurelia-router';
import { NotificationService } from '../../../services/notificationService';

interface IReportScheduleParams {
  scheduleId: string | undefined;
  action: 'new' | 'details';
}

@autoinject()
export class ReportSchedule extends GraphQLBaseViewModel<
  void,
  ReportScheduleQuery,
  ReportScheduleQueryVariables
> {
  constructor(
    private reportService: ReportService,
    private router: Router,
    private notification: NotificationService
  ) {
    super(getLogger(ReportSchedule.name));
  }

  pendingChanges: UpsertReportScheduleInput | undefined;
  confirmRemoveReport: boolean = false;
  reportIdToBeRemoved: string | null;
  toastMessage: string;
  wrapper: Element;

  activate({ scheduleId }: IReportScheduleParams) {
    if (scheduleId) {
      this.variables = {
        scheduleId
      };
    }
  }

  attached() {
    this.addFormValidationlisteners(this.wrapper);
  }

  _accValidationErrors = new Set<Element>();
  _formIsValid = true;

  addFormValidationlisteners = (thisel: Element) => {
    thisel.addEventListener('validationsuccess', (event: CustomEvent) => {
      const { element } = event.detail;
      this._accValidationErrors.delete(element);

      if (!this._accValidationErrors.size) {
        this._formIsValid = true;
      }
    });
    thisel.addEventListener('validationerrors', (event: CustomEvent) => {
      const { element } = event.detail;
      this._accValidationErrors.add(element);
      this._formIsValid = false;
    });
  };

  query = gql`
    query ReportScheduleQuery($scheduleId: ID!) {
      reportSchedule(reportScheduleId: $scheduleId) {
        reportScheduleId
        ...ReportScheduleDetailsFragment
        ...ReportScheduleReportsFragment
      }
    }
    ${ReportScheduleDetailsFragmentDefinition}
    ${ReportScheduleReportsFragmentDefinition}
  `;

  pendingRemoveReport = async (scheduleReportId: string | null) => {
    if (!this.confirmRemoveReport) this.confirmRemoveReport = true;
    if (this.reportIdToBeRemoved != scheduleReportId)
      this.reportIdToBeRemoved = scheduleReportId;
  };

  deleteScheduleReport = async (scheduleReportId: string | null) => {
    if (!scheduleReportId) return;
    const mutation = gql`
      mutation DeleteScheduleReportMutation($scheduleReportId: ID!) {
        deleteScheduledReport(scheduledReportId: $scheduleReportId)
      }
    `;
    await this.runQuery<
      DeleteScheduleReportMutation,
      DeleteScheduleReportMutationVariables
    >(
      mutation,
      {
        scheduleReportId: scheduleReportId
      }
    );
    this.revalidateAllActiveQueries();
    this.confirmRemoveReport = false;
  };

  cancelEdit() {
    this.router.navigate(`/scheduled-reports/scheduled-reports`);
  }

  deleteReportSchedule = async () => {
    const reportScheduleId = this.variables
      ? this.variables.scheduleId
      : undefined;
    if (!reportScheduleId) return;
    await this.runQuery<
      DeleteReportScheduleMutation,
      DeleteReportScheduleMutationVariables
    >(
      gql`
        mutation DeleteReportScheduleMutation($reportScheduleId: ID!) {
          deleteReportSchedule(reportScheduleId: $reportScheduleId)
        }
      `,
      {
        reportScheduleId
      }
    );

    this.router.navigate(`/scheduled-reports/scheduled-reports`);
  };

  async runReportScheduleNow() {
    if (!this.variables || !this.variables.scheduleId)
      throw Error('Missing scheduleId');
    const scheduleId = parseInt(this.variables.scheduleId, 10);

    await this.reportService.runReportScheduleNow(scheduleId);

    this.notification.notify({
      level: 'info',
      type: 'CUSTOM',
      text: 'UI_ReportSchedules_Information_RunNow_Feedback_Body',
      timestamp: new Date().toString(),
      acknowledged: false
    });
  }

  receiveReportScheduleChanges(schedule: UpsertReportScheduleInput) {
    this.pendingChanges = schedule;
  }

  save = async () => {
    if (this.pendingChanges === undefined) return;

    const mutation = gql`
      mutation SaveReportScheduleDetailsMutation(
        $input: UpsertReportScheduleInput!
      ) {
        upsertReportSchedule(input: $input) {
          reportScheduleId
          scheduleName
          recurrence
          recurrenceDetails
          enabled
          triggerTimeLocal
          timeZoneId
        }
      }
    `;

    await this.runQuery<
      SaveReportScheduleDetailsMutation_upsertReportSchedule,
      SaveReportScheduleDetailsMutationVariables
    >(
      mutation,
      {
        input: {
          recurrence: this.pendingChanges.recurrence,
          scheduleType: this.pendingChanges.scheduleType,
          enabled: this.pendingChanges.enabled,
          recurrenceDetails: this.pendingChanges.recurrenceDetails,
          reportScheduleId: this.pendingChanges.reportScheduleId,
          scheduleName: this.pendingChanges.scheduleName,
          triggerTimeLocal: this.pendingChanges.triggerTimeLocal,
          systemReportRecipients: this.pendingChanges.systemReportRecipients,
          timeZoneId: this.pendingChanges.timeZoneId
        }
      }
    );

    this.revalidateAllActiveQueries();

    this.notification.notify({
      level: 'info',
      type: 'CUSTOM',
      text: 'UI_Common_Changes_Saved',
      timestamp: new Date().toString(),
      acknowledged: false
    });

    this.pendingChanges = undefined;

  };
}

import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { ScheduledReportsListDocument } from '$typings/graphql-codegen';
import { Box, Card, CardHeader, Tab, Tabs, Typography } from '@mui/material';
import React, { FC } from 'react';
import './report-schedule-list.css';
import { useGridData } from '$components/grid/react-grid/gridhelper';
import EmptyComponent from '$components/empty-component/empty-component/empty-component.react';
import EmptyComponentDescription from '$components/empty-component/empty-component-description/empty-component-description.react';
import EmptyComponentHeader from '$components/empty-component/empty-component-header/empty-component-header.react';
import ReportScheduleUserList from './report-schedule-user-list/report-schedule-user-list';
import ReportScheduleSystemList from './report-schedule-system-list/report-schedule-system-list';
import { useIsMobile } from '$lib/hooks/isMobile';
import { getSession } from '../../../../src/config/sessionService';
import LoadingBarsReact from '$components/loading-bars/loading-bars.react';

interface IReportScheduleListProps {}

const ReportScheduleList: FC<IReportScheduleListProps> = () => {
  const data = useGridData(
    ScheduledReportsListDocument,
    {},
    t => t.reportSchedules.length || 0,
    t => t.reportSchedules
  );
  const [t] = useCaseInsensitiveTranslation();
  const isMobile = useIsMobile();
  const [tabValue, setTabValue] = React.useState(0);

  const isSuperUser = getSession().currentUser.isSuperUser;

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const content = (
    <>
      <CardHeader
        title={
          <Typography variant="h6">{t('UI_Titles_ReportSchedules')}</Typography>
        }
      />
      {!isSuperUser && <ReportScheduleUserList data={data} />}
      {isSuperUser && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="User" sx={{ width: isMobile ? '50%' : '' }} />
              <Tab label="System" sx={{ width: isMobile ? '50%' : '' }} />
            </Tabs>
          </Box>
          <TabPanel key={'tab0'} value={tabValue} index={0}>
            <ReportScheduleUserList data={data} />
          </TabPanel>
          <TabPanel key={'tab1'} value={tabValue} index={1}>
            <ReportScheduleSystemList data={data} />
          </TabPanel>
        </>
      )}
    </>
  );

  return (
    <>
      {!data.data?.length && data.isRevalidating && <LoadingBarsReact />}
      {!data.data?.length && !data.isRevalidating && (
        <div id="report-schedule-list" className="empty">
          <EmptyComponent
            logoStretchesVertically={false}
            image="/icons/events.svg"
            animated={false}
          >
            <EmptyComponentHeader>
              {t('UI_ReportSchedules_Empty_Header')}
            </EmptyComponentHeader>
            <EmptyComponentDescription>
              {t('UI_ReportSchedules_Empty_Body')}
            </EmptyComponentDescription>
          </EmptyComponent>
        </div>
      )}
      {!!data.data?.length && (
        <div id="report-schedule-list">
          {isMobile && content}
          {!isMobile && <Card>{content}</Card>}
        </div>
      )}
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  if (value !== index) return <div />;
  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default ReportScheduleList;

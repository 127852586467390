import React, { FC } from 'react';
import './empty-component-header.css';

const EmptyComponentHeader: FC = ({ children }) => (
  <div className="empty-component-header">
    <div className="page-empty-data-header">{children}</div>
  </div>
);

export default EmptyComponentHeader;

import gql from 'graphql-tag';
import { bindable, customElement } from 'aurelia-templating';
import {
  ReportScheduleDetailsFragment,
  ReportScheduleRecurrence,
  UpsertReportScheduleInput
} from '../../../../../../custom_typings/graphql';
import { GraphQLBaseViewModel } from '../../../../common/GraphQLBaseViewModel';
import { computedFrom, observable } from 'aurelia-binding';
import { isSomething, removeNoneAndEmptyFromArray } from '../../../../../utility';
import './report-schedule-details.css';
import { ScheduleTypeEnum } from '$typings/graphql-codegen';
import { getSession } from '../../../../../../src/config/sessionService';

export const ReportScheduleDetailsFragmentDefinition = gql`
  fragment ReportScheduleDetailsFragment on ReportSchedule {
    enabled
    recurrence
    recurrenceDetails
    reportScheduleId
    scheduleName
    triggerTimeLocal
    timeZoneId
    systemReportRecipients
    scheduleType
  }
`;

@customElement('report-schedule-details')
export class ReportScheduleDetails extends GraphQLBaseViewModel<
  void,
  void,
  void
> {
  recurrence = ReportScheduleRecurrence;
  reportType = ScheduleTypeEnum;
  isSuperUser = getSession().currentUser.isSuperUser;

  @observable()
  recipients: string | null;
  @observable()
  selectedRecurrence: ReportScheduleRecurrence;
  @observable({ changeHandler: 'doChangeNotify' })
  selectedRecurrenceDetails: number | null = null;

  @observable()
  selectedReportType: ScheduleTypeEnum;

  scheduleName: string | null | undefined;
  triggerTimeLocal: string;
  timeZoneId: number;

  @bindable schedule: ReportScheduleDetailsFragment;

  @bindable changeNotify:
    | ((data: { schedule: UpsertReportScheduleInput }) => void)
    | undefined
    | null;

  private disableChangeNotify = false;

  @bindable disabled: boolean = false;

  attached() {
    this.initializeLocalValues(this.schedule);
  }

  scheduleChanged() {
    this.initializeLocalValues(this.schedule);
  }

  recipientsChanged(){
    this.doChangeNotify();
  }

  selectedReportTypeChanged() {
    this.doChangeNotify();
  }

  selectedRecurrenceChanged() {
    this.selectedRecurrenceDetails = 0;
    this.doChangeNotify();
  }

  updateScheduleName(value: string | null) {
    this.scheduleName = value;
    this.doChangeNotify();
  }

  updateTriggerTimeLocal(value: string) {
    this.triggerTimeLocal = value;
    this.doChangeNotify();
  }

  updateTimezone(value: string) {
    this.timeZoneId = parseInt(value, 10);
    this.doChangeNotify();
  }

  @computedFrom('selectedRecurrence')
  get showWeeklyRecurrenceOptions() {
    return this.selectedRecurrence === ReportScheduleRecurrence.WeeklyByWeekDay;
  }

  @computedFrom('selectedRecurrence')
  get showMonthlyRecurrenceOptions() {
    return (
      this.selectedRecurrence === ReportScheduleRecurrence.MonthlyByFirstWeekDay
    );
  }

  @computedFrom('recipients')
  get allRecipients() { 
    return this.recipients?.split(",")
  }

  recipientsKeyPress = (e: KeyboardEvent) => {
    if (e.code !== 'Enter') return;
    const inputEl = e.target as HTMLInputElement;
    this.recipients = removeNoneAndEmptyFromArray([
      ...(this.recipients ? this.recipients.split(',') : []),
      ...inputEl.value.split(',').map(v => v.trim())
    ]).join(",");    

    inputEl.value = '';
    e.preventDefault();
    return false;
  }

  removeRecipient = (recipient: string) => {
    this.recipients = this.recipients?.split(",").filter(r => r !== recipient).join(",") || null
  }

  private initializeLocalValues(schedule: ReportScheduleDetailsFragment) {    
    if (isSomething(schedule)) {      
      this.disableChangeNotify = true;
      this.selectedRecurrence = this.schedule.recurrence;
      this.selectedReportType = this.schedule.scheduleType;
      this.recipients = this.schedule.systemReportRecipients;
      if (typeof this.schedule.recurrenceDetails === 'number')
        this.selectedRecurrenceDetails = this.schedule.recurrenceDetails;
      else this.selectedRecurrenceDetails = 0;
      this.triggerTimeLocal = this.schedule.triggerTimeLocal;
      this.timeZoneId = this.schedule.timeZoneId;
      this.scheduleName = this.schedule.scheduleName;
      this.disableChangeNotify = false;
    }
  }

  private doChangeNotify() {
    // note: changeNotify may become null during view teardown:
    if (isSomething(this.changeNotify) && !this.disableChangeNotify)
      this.changeNotify({ schedule: this.scheduleWithPendingChanges });
  }

  private get scheduleWithPendingChanges(): UpsertReportScheduleInput {
    let reportScheduleId: string | null = null;
    let enabled: boolean | null = true;
    if (isSomething(this.schedule)) {
      reportScheduleId = this.schedule.reportScheduleId;
      enabled = this.schedule.enabled;
    }

    let recurrence = this.selectedRecurrence;
    let scheduleType = this.selectedReportType;

    let recurrenceDetails =
      this.selectedRecurrence !== ReportScheduleRecurrence.Daily
        ? this.selectedRecurrenceDetails
        : null;
    if (recurrence == ReportScheduleRecurrence.MonthlyByDate)
      recurrenceDetails = 0;

    const scheduleName = isSomething(this.scheduleName)
      ? this.scheduleName.trim()
      : this.scheduleName;
      
    return {
      reportScheduleId,
      enabled,
      scheduleName,
      triggerTimeLocal: this.triggerTimeLocal,
      timeZoneId: this.timeZoneId,
      recurrence,
      recurrenceDetails,
      scheduleType,
      systemReportRecipients: this.recipients
    };
  }

  validateReportScheduleName() {
    if (typeof this.scheduleName !== 'string') {
      return false;
    }
    if (this.scheduleName.trim() === '') {
      return 'UI_Common_ValidationErrors_Only_Spaces';
    }
    return true;
  }
}

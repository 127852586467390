import { RouterConfiguration } from 'aurelia-router';
import { PLATFORM } from 'aurelia-framework';

export class ScheduledReports {
  configureRouter(config: RouterConfiguration) {
    config.map([
      {
        route: '',
        name: 'report-schedule-list',
        moduleId: PLATFORM.moduleName(
          './report-schedule-list/report-schedule-list'
        )
      },
      {
        route: ':action/:scheduleId?',
        name: 'report-schedule',
        moduleId: PLATFORM.moduleName('./report-schedule/report-schedule')
      }
    ]);
  }
}

import { isSomething, timeBetweenDateTranslation } from '..';
import { useCaseInsensitiveTranslation } from './case-insensitive-translation';

export const useTimeUntil = () => {
  const [t] = useCaseInsensitiveTranslation();
  const func = (value?: string | Date | null, prependCount = true) => {
    if (!isSomething(value)) return;
    const timeUntil = timeBetweenDateTranslation(true, value);
    if (!timeUntil) return;
    return (
      (prependCount ? timeUntil.count : '') +
      ' ' +
      t(timeUntil.key, { count: timeUntil.count })
    );
  };

  return [func];
};
